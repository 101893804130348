
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "App",
  components: {},
  data() {
    return {
      draw: false,
    };
  },
  watch: {
    navdraw: function (v) {
      this.draw = v;
    },
  },
  computed: {
    ...mapGetters("services", ["allServices"]),
    navdraw() {
      return this.$store.state.navdraw;
    },
  },
  methods: {
    closeDraw() {
      this.$store.commit("closeNavdraw");
    },
    goto_home() {
      this.$router.push("#top");
    },
    goto_services(name: string) {
      this.$router.push(`#${name}`);
    },
    goto_about() {
      this.$router.push("#about");
    },
    goto_contact() {
      this.$router.push("#contact");
    },
  },
});
