
export interface SocialMedia {
  name: string
  link: string
  color: string
  icon: string
}

export interface BusinessState {
  address: string
  tel: string
  email: string
  regNo: string
  socialMedia: [SocialMedia]
}

export default {
  namespaced: true,
  state: {
    address: '',
    tel: '07943032901',
    email: 'nolansparkleshine@outlook.com',
    regNo: '',
    socialMedia: [
      {
        name: 'facebook',
        link: 'https://www.facebook.com/groups/457291196114971',
        icon: 'mdi-facebook',
        color: 'blue-darken-2',
        hoverColor: 'blue-darken-4',
      },
      {
        name: 'instagram',
        link: 'https://www.facebook.com/groups/457291196114971',
        icon: 'mdi-instagram',
        color: 'purple-darken-2',
        hoverColor: 'purple-darken-4',
      }
    ]
  },
  getters: {
    getAddress: (state: BusinessState) => {
      return state.address
    },
    getTelephone: (state: BusinessState) => {
      return state.tel
    },
    getEmail: (state: BusinessState) => {
      return state.email
    },
    getBusinessRegistrationNumber: (state: BusinessState) => {
      return state.regNo
    },
    getSocialMedia: (state: BusinessState) => {
      return state.socialMedia
    }
  },
  mutations: {},
  actions: {},
  modules: {},
}