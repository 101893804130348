import Category from './categories';
import ItineraryItem, {
    Bathing,
    Beds,
    Bins,
    CobwebRemoval,
    Cooker,
    Cupboards,
    Doors,
    Dusting,
    Floors,
    Heaters,
    Kickboards,
    KitchenAppliances,
    KitchenSurfaces,
    Mirrors,
    QA,
    Sinks,
    Skirting,
    StainlessSteel,
    Stairs,
    Static,
    Surfaces,
    Switches,
    Tiles,
    Toilets,
    WindowSills
} from '@/types/itinerary';

interface Facility {
    type: Category
    name: string
    image: string
    description: string
    itinerary: ItineraryItem[]
}

export default Facility

const KitchenDeep: Facility = {
    type: Category.DOMESTIC,
    name: "Kitchen",
    description: "Deep cleaning in the kitchen",
    image: "/img/backgrounds/kitchen.jpg",
    itinerary: [
        Dusting,
        CobwebRemoval,
        KitchenSurfaces,
        Tiles,
        Sinks,
        StainlessSteel,
        Floors,
        KitchenAppliances,
        Cooker,
        Cupboards,
        Doors,
        Kickboards,
        Switches,
        WindowSills,
        Bins,
        QA,
    ]
}

const Bedrooms: Facility = {
    type: Category.DOMESTIC,
    name: "Bedrooms",
    description: "Description",
    image: "/img/backgrounds/bed+room.jpg",
    itinerary: [
        Dusting,
        CobwebRemoval,
        Floors,
        Beds,
        Mirrors,
        Static,
        Surfaces,
        Doors,
        Switches,
        WindowSills,
        QA,
    ]
}

export {
    KitchenDeep,
    Bedrooms
};