interface ItineraryItem {
    name: string
    description: string
}

const Dusting: ItineraryItem = {
    name: 'Dusting',
    description: 'Removal of dust build up, including from the most neglected areas.'
}

const Skirting: ItineraryItem = {
    name: 'Skirting',
    description: 'Removal of dust and debris.'
}

const CobwebRemoval: ItineraryItem = {
    name: 'Cobwebs',
    description: 'Removing cobwebs from ceilings, walls, behind appliances, cupboards and everywhere else.'
}

const WindowSills: ItineraryItem = {
    name: 'Window sills',
    description: 'Cleaning window sills from common moisture build-up to prevent mold.'
}

const Doors: ItineraryItem = {
    name: 'Doors, frames & handles',
    description: 'Cleaning doors, frames and sterilizing common touch surfaces such as door handles.'
}

const Static: ItineraryItem = {
    name: 'Static',
    description: 'Cleaning and positioning of static objects, such as ornaments & picture frames.'
  }

const Switches: ItineraryItem = {
    name: 'Light switches & sockets',
    description: 'Cleaning switches and sockets to prevent the build up of grime and grease.'
}

const KitchenSurfaces: ItineraryItem = {
    name: 'Kitchen tops & surfaces',
    description: 'Cleaning kitchen surfaces till they sparkle & shine.'
}

const StainlessSteel: ItineraryItem = {
    name: 'Stainless Steel',
    description: 'Cleaning stainless steal and removing watermarks.'
}

const Mirrors: ItineraryItem = {
    name: 'Glass & mirrors',
    description: 'Remove finger prints and dirt from glass surfaces.'
}

const Cooker: ItineraryItem = {
    name: 'Cooker hobs & hood',
    description: 'Full external cooker clean including cooker hoods and splash boards.'
}

const KitchenAppliances: ItineraryItem = {
    name: 'Appliances',
    description: 'Cleaning the outside of kitchen appliances and inside of microwaves.'
}

const Cupboards: ItineraryItem = {
    name: 'Cupboard doors & handles',
    description: 'Ensuring common touch surfaces are free from splashes and spills.'
}

const Sinks: ItineraryItem = {
    name: 'Sinks',
    description: 'Cleaning sinks and removing common sinkhole debris. Cleaning tabs and splashback.'
}

const Bins: ItineraryItem = {
    name: 'Bins',
    description: 'Emptying bins and removal of waste.'
}

const Floors: ItineraryItem = {
    name: 'Floors',
    description: 'Cleaning and sterilizing hard floors. Vaccuming carpets and rugs.'
}

const Stairs: ItineraryItem = {
    name: 'Stairs, banisters & spindles',
    description: 'Vaccuming stairs and cleaning banisters & spindles.'
}

const Kickboards: ItineraryItem = {
    name: 'Spot check kickboards',
    description: 'Ensuring common splash surfaces are free from water marks or dirt.'
}

const Toilets: ItineraryItem = {
    name: 'Toilet & pedestal',
    description: 'Full toilet clean.'
}

const Bathing: ItineraryItem = {
    name: 'Baths/Showers',
    description: 'Full bath/showing clean.'
}

const Tiles: ItineraryItem = {
    name: 'Tiles',
    description: 'Tiles around sinks, baths and showers.'
}

const Heaters: ItineraryItem = {
    name: 'Radiators/Heated towel rails',
    description: 'Wipe down of radiators and towel rails.'
}

const Beds: ItineraryItem = {
    name: 'Beds',
    description: 'Make beds and fresh linen.'
}

const Surfaces: ItineraryItem = {
    name: 'Surfaces',
    description: 'Clean surfaces',
}

const QA: ItineraryItem = {
    name: 'Quality assurance & finishing touches',
    description: 'All cleaning services end up a quality assurance to guarentee satisfaction.'
}

export default ItineraryItem

export {
    Dusting,
    CobwebRemoval,
    WindowSills,
    Doors,
    Static,
    Switches,
    Skirting,
    Floors,
    Stairs,
    KitchenSurfaces,
    KitchenAppliances,
    StainlessSteel,
    Cupboards,
    Mirrors,
    Kickboards,
    Cooker,
    Sinks,
    Toilets,
    Heaters,
    Tiles,
    Bathing,
    Bins,
    Beds,
    Surfaces,
    QA
};