
import { defineComponent, ref } from "vue";
import { mapGetters } from "vuex";
import { send } from "@/services/email";

export default defineComponent({
  name: "ContactFormComponent",
  props: {
    title: {
      type: String,
      default: "Contact us",
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    selectedQueryType: {
      type: String,
    },
    serviceName: {
      type: String,
    },
  },
  data: () => ({
    lockSelectors: false,
    inquiryTypes: [
      "Service",
      "Free quote",
      "One off cleans",
      "Weekly services",
      "Fortnightly services",
      "Existing customer",
    ],
    form: {
      ref: ref(),
      name: "",
      phone: "",
      email: "",
      postcode: "",
      message: "",
      queryType: "",
      service: "",
    },
    rules: {
      name: (value: string) => {
        return /^[a-zA-Z ]{4,50}$/.test(value) || "invalid length for name";
      },
      phone: (alternateContact: string) => (value: string) => {
        return !!alternateContact
          ? true
          : /^[0-9]{11,11}$/.test(value) || "invalid phone number";
      },
      postcode: (value: string) => {
        return (
          /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
            value
          ) || "invalid postcode"
        );
      },
      service: (selectedService: string) => (value: string) => {
        return selectedService !== "Service"
          ? true
          : !!value || "You must select a service";
      },
      required: (value: string) => {
        return !!value || "Required.";
      },
      email: (alternateContact: string) => (value: string) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!alternateContact
          ? true
          : pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  created() {
    if (this.selectedQueryType) {
      this.form.queryType = this.selectedQueryType;
      this.lockSelectors = true;
    }
    if (this.serviceName) {
      this.form.queryType = "Service";
      this.form.service = this.serviceName;
      this.lockSelectors = true;
    }
  },
  computed: {
    ...mapGetters("services", ["allServices"]),
    display() {
      return this.$vuetify.display;
    },
  },
  methods: {
    validate() {
      return this.form.ref.validate();
    },
    async submit() {
      if ((await this.validate()).valid) {
        this.$emit("submit");
        const subject = `Potential client requested a call`;
        const text = `Hi,\n${this.form.name} request you contact them on ${[
          this.form.phone,
          this.form.email,
        ]
          .filter((e) => e.length > 0)
          .join(", ")}\nRegarding ${
          this.form.queryType === "Service"
            ? `the '${this.form.service}'`
            : `'${this.form.queryType}'`
        }${
          this.form.message.length > 0
            ? `\nThey said: ${this.form.message}`
            : ""
        }\n\nRegards\nThe Nolan Sparkle & Shine dev team.`;
        await send(subject, text)
          .then(() => {
            this.$store.commit("showSnackbar", {
              text: "Thank you your message was sent",
              color: "success",
            });
          })
          .catch((e: Error) => {
            console.log("error", e);
            this.$store.commit("showSnackbar", {
              text: "Sorry, unable to send your message right now. Please try again later.",
              color: "error",
            });
          });
      }
    },
  },
});
