
import { defineComponent } from "vue";
import Header from "@/components/header/header.vue";
import Navdraw from "@/components/navdraw/navdraw.vue";
import Footer from "@/components/footer/footer.vue";
import ContactForm from "@/components/forms/contact.vue";
import { clientLogin } from "@/services/auth";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Navdraw,
    ContactForm,
    Footer,
  },
  data() {
    return {};
  },
  created() {
    console.log(document.cookie);
    clientLogin()
      .then(() => console.log("client logged in"))
      .catch((e) => console.log(e.message));
  },
  computed: {
    display() {
      return this.$vuetify.display;
    },
  },
});
