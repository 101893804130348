import Service, { Regular, OneOffs, Spring, DeepClean, Renovation, EndOfTenancy, Event, Holiday, Laundry } from '@/types/service'
import Category from '@/types/categories'
import Policy, { EcoPolicy, StaffPolicy, BudgetPolicy, InsurancePolicy } from '@/types/policies'

interface ServiceState {
  services: [Service]
  categories: Object
  policies: Policy[]
}

export default {
  namespaced: true,
  state: {
    services: [Regular, OneOffs, Spring, DeepClean, Renovation, EndOfTenancy, Event, Holiday, Laundry],
    categories: {
      [Category.DOMESTIC]: {
        overview: 'We provide outstanding home cleaning services, always taylored to you and the needs of your home.',
        icon: "mdi-home",
        image: "/img/categories/home.jpg",
      },
      [Category.BUSINESS]: {
        overview: 'Our business services are professional and reliable. We know what it takes to craft business relations.',
        icon: "mdi-office-building",
        image: "/img/categories/office.jpg",
      },
      [Category.LAUNDRY]: {
        overview: 'We can take care of your laundry needs, from washing to ironing.',
        icon: "mdi-tshirt-crew-outline",
        image: "/img/categories/laundry.jpg",
      }
    },
    policies: [
      EcoPolicy, StaffPolicy, BudgetPolicy, InsurancePolicy
    ]
  },
  getters: {
    getCategories: (state: ServiceState) => {
      return state.categories
    },
    getPolicies: (state: ServiceState) => {
      return state.policies
    },
    allServices: (state: ServiceState) => {
      return state.services;
    },
    servicesByCategory: (state: ServiceState) => (category: Category) => {
      return state.services.filter(service => service.categories.includes(category))
    },
    serviceByName: (state: ServiceState) => (name: string) => {
      for (const service of state.services) {
        if (service.name === name) {
          return service;
        }
      }
    }
  },
  mutations: {},
  actions: {},
  modules: {},
}