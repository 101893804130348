
interface Policy {
    id: string
    name: string
    image?: string
    brief: string
    description: string
}

const EcoPolicy: Policy = {
    id: 'eco-policy',
    name: 'Eco friendly products',
    brief: 'Looking out for nature',
    description: 'We care about the environment. We only use eco friendly, trusted products in our cleans.',
    image: '/img/policies/eco.jpg'
}

const StaffPolicy: Policy = {
    id: 'staff-policy',
    name: 'Professional staff',
    brief: 'Fully vetted staff',
    description: 'We have rigorous hiring practices to ensure our staff are trustworthy. All our staff must pass a DBS check to prove they have no criminal history. We have a zero tolerance approach to misconduct.',
    image: '/img/policies/staff.jpg'
}

const BudgetPolicy: Policy = {
    id: 'budget-policy',
    name: 'Bespoke Cleaning',
    brief: 'On your budget',
    description: 'Our cleaning services are fully bespoke. We offer flexible plans so you can customise our service to your needs.',
    image: '/img/policies/budget.jpg'
}

const InsurancePolicy: Policy = {
    id: 'insurance-policy',
    name: 'Insurance',
    brief: 'Fully insured',
    description: 'We\'re insured to work in your home, which means you\'re covered from accidental damages to your property.',
    image: '/img/policies/insured.jpg'
}


export default Policy

export {
    EcoPolicy,
    StaffPolicy,
    BudgetPolicy,
    InsurancePolicy
}

