/*
Dont:
    - Clean inside ovens

Spot check: 
    - Quick wipe over only.

D&R have
    Regular
    One-off
    Double/spring
        - Extra cleaning but not a deep clean
    Deep
        - Clean under furniture
        - vaccum under sofa cusions
        - Cream clean doors and skirting
        - Tiles scrubbed
        - Kitchen kickboards wiped over.
    End of tenancy 
    Extra optional tasks:
        - Inside windows
        - Washing & ironing services 

O&B includes
    type: office, shop, pub, business
    Event
    Regular
    Holiday letting
    Occasional Deep clean
    Builders clean
    Extra optional tasks:
        - Inside windows
*/

import Category from './categories';
import Facility, {
    KitchenDeep,
    Bedrooms
} from './facilities';

interface Service {
    id: string
    name: string
    categories: Category[]
    brief: string
    thumbnail: string
    icon?: string
    facilities?: Facility[]
    descriptions: string[]
    related: string[]
}

const DeepClean: Service = {
    id: "deep",
    icon: "mdi-bucket-outline",
    categories: [
        Category.DOMESTIC,
        Category.BUSINESS
    ],
    facilities: [
        KitchenDeep,
        Bedrooms,
    ],
    name: "Deep Clean",
    brief: "Sparkle and shine with a deep clean",
    thumbnail: '/img/service+types/deep+clean.jpg',
    descriptions: [
        "Our deep cleaning service will bring a sparkle to any home. We will pull out that sofa, scrumb those tiles and polish every nook. You know your home and how it should be, so we will give extra attention wherever you think it's needed.",
        "We always recommend deep cleans for our new clients. This is so we have a blank canvas we then maintain on a regular basis.",
        "It's our most comprehensive service and promise to leave no stone unturned. We won't rest until your home Sparkles and shines."
    ],
    related: [
        "eco-policy",
        "window-policy",
    ]
}

const EndOfTenancy: Service = {
    id: "eot",
    icon: "mdi-home-outline",
    categories: [
        Category.DOMESTIC,
        Category.BUSINESS
    ],
    name: "End of tenancy clean",
    brief: "Selling or cleaning up after a messy tenant?",
    thumbnail: '/img/service+types/end+of+tenancy.jpg',
    descriptions: [
        "Our teams will get your home ready for it's new tenant. Whether you're looking to impress a potential buyer or just clean up for a new tenant, we'll be sure to make it sparkle and shine.",
        "We aim to restore cleanliness to your property. If you rent your property your landlord will expect the property to be handed back in the condition it arrived. Our end of tenancy clean is sure to pass the test."
    ],
    related: [
        "eco-policy",
        "window-policy",
    ]
}

const Event: Service = {
    id: "event",
    icon: "mdi-balloon",
    categories: [
        Category.BUSINESS
    ],
    name: "Event clean",
    brief: "Big events. Leave big messes",
    thumbnail: '/img/service+types/events.jpg',
    descriptions: [
        "Events such as parties can require a special kind of one-off clean. Our event cleaning service will get rid of the mess, so you can get back to your regular cleaning schedule quickly.",
        "We know not all events are the same, so whether you require a touch up or full scale clean. We're on it."
    ],
    related: [
        "eco-policy",
        "window-policy",
    ]
}

const Holiday: Service = {
    id: "holiday",
    icon: "mdi-bed-outline",
    categories: [
        Category.DOMESTIC,
        Category.BUSINESS
    ],
    name: "Holiday Let Clean",
    brief: "Shiny, sparkling holiday and let cleaning services",
    thumbnail: '/img/service+types/holiday+letting.jpg',
    descriptions: [
        "Guests notice when their rooms haven't been cleaned well. Having a reliable cleaning service to maintain the cleanliness of your holiday home is paramount to providing a welcoming environment for guests.",
        "We're all about delivering exceptional cleanliness for your guests. It's sure to leave a sparkling impression."
    ],
    related: [
        "eco-policy",
        "window-policy",
    ]
}

const OneOffs: Service = {
    id: "oneoffs",
    icon: "mdi-handshake-outline",
    categories: [
        Category.DOMESTIC
    ],
    name: "One-off Clean",
    brief: "Domestic cleaning services on a whim",
    thumbnail: '/img/service+types/one+off.jpg',
    descriptions: [
        "Sometimes we can all neglect the cleaning requirements of our home. Which is why we sometimes require a boost to get back on top of our schedules. Our one off clean will get your home back up to scratch.",
        "Our one-offs are completely bespoke. So you can give special attention to the most neglected areas of your home.",
    ],
    related: [
        "eco-policy",
    ]
}

const Regular: Service = {
    id: "regular",
    icon: "mdi-broom",
    categories: [
        Category.DOMESTIC,
        Category.BUSINESS
    ],
    name: "Regular Clean",
    brief: "Domestic & Business cleaning services on a regular basis",
    thumbnail: '/img/service+types/regular.jpg',
    descriptions: [
        "Whether you want a weekly or fortnightly service we have a package for you. We know that modern life doesn't care about your cleaning scedule, but we do! Our aim is to make your residence sparkle and shine so you can focus on more enjoyable things in life. We can clean when you're out! No problem. We have strict security protocols for key holders and all of our teams are DBS checked.",
        "We start all new regular cleans with an initial deep clean, which enables us to bring each home up to a standard we then maintain on a regular basis. Once the initial clean is done, we attend at a frequency that best suits you.",
        "A service designed by you. Just let us know your requirements and how regularly you want our service and we will do the rest."
    ],
    related: [
        "key-policy",
    ]
}

const Renovation: Service = {
    id: "renovation",
    icon: "mdi-ladder",
    categories: [
        Category.DOMESTIC,
        Category.BUSINESS
    ],
    name: "Renovation clean",
    brief: "Tradesman’s mess? Don't stress",
    thumbnail: '/img/service+types/renovation.jpg',
    descriptions: [
        "Renovations are messy work. Fortunately mess doesn't Stand a chance with us. Your building project is sure to have put a spanner into your cleaning schedule. We provide a deep clean that will remove dirt and dust from any building project.",
        "We'll be happy to clean out that dust and bring freshness back into your property, so you can enjoy your new transformation."
    ],
    related: [
        "eco-policy",
        "window-policy",
    ]
}

const Spring: Service = {
    id: "spring",
    icon: "mdi-leaf-maple",
    categories: [
        Category.DOMESTIC,
    ],
    name: "Spring Clean",
    brief: "Domestic cleaning services on mostly clean homes",
    thumbnail: '/img/service+types/spring.jpg',
    descriptions: [
        "Our spring cleaning services are for homes that don't require a full deep clean. We do spring cleans every so often to maintain your standard of cleanliness.",
    ],
    related: [
        "window-policy",
    ]
}

const Laundry: Service = {
    id: "laundry",
    icon: "mdi-hanger",
    categories: [
        Category.LAUNDRY,
    ],
    name: "Washing, Drying & Ironing",
    brief: "We’ll take your laundry to the dry cleaners!",
    thumbnail: '/img/service+types/laundry.jpg',
    descriptions: [
        "As an extra service we can help out with your laundry.",
    ],
    related: [
        "laundry-policy",
    ]
}

export {
    DeepClean,
    EndOfTenancy,
    Event,
    Holiday,
    OneOffs,
    Regular,
    Renovation,
    Spring,
    Laundry,
}

export default Service