import axios from 'axios';
import config from '@/config'

const authApi = axios.create({
    baseURL: config.server.baseUrl,
    withCredentials: true
})

const clientLogin = async () => {
    return await authApi.post("/v1/auth/api/login", {
        ...config.server.auth
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export {
    clientLogin,
};