
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderComponent",
  data() {
    return {
      serviceMenu: false,
      windowTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    display() {
      return this.$vuetify.display;
    },
  },
  methods: {
    toggleDraw() {
      this.$store.commit("toggleDraw");
    },
    goto_home() {
      this.$router.push("#top");
    },
    goto_services(name: string) {
      this.serviceMenu = false;
      this.$router.push(`#${name}`);
    },
    goto_about() {
      this.$router.push("#about");
    },
    goto_contact() {
      this.$router.push("#contact");
    },
    onScroll() {
      this.windowTop = window.top?.scrollY || 0;
    },
  },
});
