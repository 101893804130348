import { createStore } from "vuex";
import cleaningStore from "./cleaning/store"
import businessStore from "./business/store"

export default createStore({
  state: {
    navdraw: false,
    homeview: {
      heading: 'Reliable and professional housekeepers\n for regular or occasional cleaning.',
    },
    popupContactForm: {
      show: false,
      title: '',
      selectedQuery: '',
      selectedService: ''
    },
    snackbar: {
      show: false,
      text: "",
      color: "error",
    }
  },
  getters: {},
  mutations: {
    toggleDraw(state) {
      state.navdraw = !state.navdraw
    },
    closeNavdraw(state) {
      state.navdraw = false;
    },
    showPopupContactForm(state, payload) {
      state.popupContactForm = {
        show: true,
        ...payload
      }
    },
    closePopupContactForm(state) {
      state.popupContactForm.show = false;
    },
    showSnackbar(state, payload) {
      state.snackbar = {
        show: true,
        ...payload
      }
    },
    closeSnackbar(state) {
      state.snackbar.show = false
    }
  },
  actions: {},
  modules: {
    services: cleaningStore,
    business: businessStore
  },
});
