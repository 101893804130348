export default {
  dark: false,
  colors: {
    'background': '#ffffff',
    'surface': '#00AEFF',
    'primary': '#941C2F',
    'secondary': '#EAD2AC',
    'error': '#F15025',
    'info': '#5F4BB6',
    'success': '#8FC93A',
    'warning': '#E8871E',
  },
};
