import axios from 'axios';
import config from '@/config'

const emailAPI = axios.create({
    baseURL: config.server.baseUrl,
    withCredentials: true
})

const send = async (subject: String, text: String) => {
    return await emailAPI.post("/v1/email", {
        subject,
        text,
        dryrun: process.env.VUE_APP_EMAIL_DRY_RUN || false
    });
}

export {
    send,
};