import { createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior } from 'vue-router';
const HomeView = () => import(/* webpackChunkName: "default" */ '../views/HomeView.vue');

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to, from, savedPosition) {
    if (from.path !== to.path) {
      console.log("scroll top")
      document.getElementById('top')?.scrollIntoView();
    }
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => resolve({
          el: to.hash,
          top: 65,
          behavior: 'smooth',
        }), 300)
      })
    } else {
      return {
        el: '#top'
      }
    }
  } as RouterScrollBehavior,
  routes,
});

export default router;
