
export default {
    server: {
        baseUrl: process.env.VUE_APP_SERVER_URL || process.env.BASE_URL,
        auth: {
            clientId: process.env.VUE_APP_CLIENT_ID,
            clientSecret: process.env.VUE_APP_CLIENT_SECRET,
        },
        api: {
            email: {
                dryrun: process.env.VUE_APP_EMAIL_DRY_RUN || false
            }
        }
    }
}